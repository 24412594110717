@charset "utf-8";

/*---------------------------------------
main
----------------------------------------*/
body#pageTop main {
    position: relative;
    overflow: hidden;
}

#pageTop #contents {
    width: 100%;

    section > h2 {
        margin-bottom: 14px;
        padding: 7px 0 0 70px;
        border-bottom: 2px solid #2A65B7;
        font-size: 250%;

        font-weight: bold;
        color: #2a65b7;

        span {
            padding: 0 15px 5px 0;
            font-size: 40%;

            font-weight: bold;
            color: #2a65b7;
            vertical-align: middle;
        }
    }
}

.linkList {
    margin: -48px 0 35px auto;
    width: 7em;
    padding-left: 22px;
    background: url(/assets/pc/images/icon_arrow_06.png) no-repeat left center;
    display: block;
}

/*---------------------------------------
#mainVisualArea
----------------------------------------*/
#mainVisualArea {
    margin-bottom: 20px;
    height: 530px;
    background: url(/assets/pc/images/top/mainvisual_bg_02.png) repeat-x bottom center, url(/assets/pc/images/top/main_bg_02.png) repeat-x top center;
    position: relative;

    .mainVisualBox {
        background: url(/assets/pc/images/top/cloud_02.png) no-repeat;
        background-position: calc(50% - 85px) 40px;
    }

    .mainVisualInner {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        width: 1020px;
        height: 513px;

        .catch {
            padding-left: 10px;
            width: 383px;

            h1 {
                position: relative;
                right: 20px;
            }

            .lead {
                margin-top: -25px;
                font-weight: bold;

                span {
                    padding: 0 3px;
                    font-size: 170%;
                    font-weight: bold;
                    color: #FC6618;
                }
            }

            & .results {
                margin-top: -25px;
                display: flex;
                & > div {
                    font-weight: bold;
                    &.operation {
                        margin-right: 16px;
                    }
                }
                & span {
                    &.number {
                        margin: 0 3px 0;
                        font-size: 170%;
                        font-weight: bold;
                        color: #fc6618;
                    }
                    &.case {
                        margin-right: 16px;
                        font-weight: bold;
                    }
                }
                & + p {
                    margin-bottom: 25px;
                    font-size: 88%;
                    line-height: 1.6;
                }
            }

            .freePhoneBox {
                margin-left: -15px;
                padding: 7px 30px;
                position: relative;
                width: 380px;
                background: #fff;
                box-sizing: border-box;

                .balloon {
                    position: absolute;
                    top: -25px;
                    right: -5px;
                }

                .number {
                    color: #319f2c;
                    font-size: 257%;
                    font-weight: bold;
                    line-height: 1.3;
                    word-wrap: break-word;
                    display: block;
                    border: none;
                    text-decoration: none;

                    img {
                        padding-bottom: 5px;
                        display: block;
                    }
                }

                .poweredBy {
                    display: block;
                    color: #777;
                    font-size: 63%;
                    line-height: 1.4;
                }
            }
        }

        .map {
            width: 560px;
            position: relative;
            background: url(/assets/pc/images/top/map.png) no-repeat 0 0;

            .lead {
                position: absolute;
                top: 45px;
                right: 178px;
                width: 161px;
                line-height: 1.4;
                text-align: center;
                font-weight: bold;

                span {
                    padding: 0 3px;
                    font-size: 130%;
                    font-weight: bold;
                    color: #FC6618;
                }

                em {
                    font-size: 81%;
                    font-weight: bold;
                    color: #777;
                }
            }

            ul {
                padding: 6px;
                position: absolute;
                display: flex;
                flex-wrap: wrap;
                background-color: rgba(90, 149, 230, 0.9);
                border-radius: 5px;
                box-sizing: border-box;

                li {
                    margin: 6px 6px 0 0;
                    width: 55px;

                    a {
                        padding: 2px 0;
                        background-image: linear-gradient(180deg, #fff 0, #f6f6f4 50%, #e9e8e4 100%);
                        width: 100%;
                        min-height: 14px;
                        border: 1px solid #aaa;
                        border-radius: 5px;
                        text-align: center;
                        text-decoration: none;
                        color: #333;
                        display: block;
                        box-sizing: border-box;

                        &:hover {
                            background: #ff8000;
                            color: #fff;
                        }
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            .area01 {
                width: 67px;
                top: 115px;
                right: 37px;

                li {
                    margin: 0;
                }
            }

            .area02 {
                width: 128px;
                top: 170px;
                right: 37px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area03 {
                width: 128px;
                bottom: 30px;
                right: 70px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area04 {
                width: 128px;
                top: 105px;
                left: 195px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area05 {
                width: 128px;
                bottom: 47px;
                right: 203px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area06 {
                width: 128px;
                bottom: 6px;
                left: 96px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area07 {
                width: 67px;
                top: 46px;
                left: 118px;

                li {
                    margin-right: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .area08 {
                width: 128px;
                bottom: 6px;
                left: -37px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area09 {
                width: 128px;
                top: 46px;
                left: -20px;

                li:first-child,
                li:nth-child(2) {
                    margin-top: 0;
                }
            }

            .area10 {
                width: 67px;
                top: 223px;
                left: -20px;

                li {
                    margin: 0;
                }
            }
        }
    }
}

/*---------------------------------------
.contentsBannerArea
----------------------------------------*/

.contentsBannerArea {
    margin:0 auto 20px;
    width: 1000px;
    display: flex;
    & li {
        &:first-child {
            margin-right: 20px;
        }
        & a {
            display: block;
            line-height: 1;
            &:hover img {
                opacity: 0.8;
            }
        }
    }
}

/*---------------------------------------
#statusArea
----------------------------------------*/
#statusArea {
    margin: 0 auto 20px;
    display: flex;
    justify-content: space-between;
    width: 1000px;

    & > a {
        margin-bottom: 20px;
        display: block;

        &:hover {
            opacity: 0.8;
        }

        img {
            display: block;
        }
    }
}

/*---------------------------------------
.recentArrivalArea,
.infoArea
----------------------------------------*/
#statusArea .recentArrivalArea,
#statusArea .infoArea {
    padding: 10px;
    width: 490px;
    height: 200px;
    border: 1px solid #ccc;
    background-color: #DFEAF9;
    box-sizing: border-box;
}

#statusArea .recentArrivalArea h2,
#statusArea .infoArea h2 {
    margin-bottom: 7px;
    padding-left: 33px;
    font-weight: normal;
    color: #333;
    display: block;
}

#statusArea .recentArrivalArea h2 {
    background: url(/assets/pc/images/icon_bell.png) no-repeat 2px 2px;
}

#statusArea .infoArea h2 {
    background: url(/assets/pc/images/icon_attention_01.png) no-repeat 0 1px;
}

#statusArea .recentArrivalArea > ul,
#statusArea .infoArea .infoBox {
    width: 470px;
    height: 140px;
    padding: 7px 8px;
    background-color: #FFF;
    overflow-y: scroll;
    box-sizing: border-box;
}

#statusArea .infoArea .infoBox {
    height: 140px;
}

#statusArea .infoArea article a {
    display: block;
}

#statusArea .recentArrivalArea > ul > li,
#statusArea .infoArea .infoBox article {
    padding: 9px 0 7px;
    border-bottom: 1px dotted #777;
    line-height: 1.4;

    span {
        color: #a3a3a3;
    }
}

#statusArea .recentArrivalArea li ul li {
    padding-left: 1em;
    background: url(/assets/pc/images/marker_dot.gif) no-repeat 0.4em 0.6em;
}

#statusArea .infoArea a.buttonList {
    margin: -35px 0 7px auto;
    width: 5em;
    padding-left: 22px;
    background: url(/assets/pc/images/icon_arrow_06.png) no-repeat left center;
    display: block;
}

/*---------------------------------------
.featureArea
----------------------------------------*/
#pageTop #contents section.featureArea {
    margin: 0 auto 70px;
    position: relative;
    width: 1000px;

    h2 {
        margin: 0;
        background: url(/assets/pc/images/title_main_01.png) no-repeat left center;
    }

    .button {
        display: inline-block;
        position: absolute;
        bottom: -20px;
        right: 0;
        text-align: right;

        span {
            padding: 4px 10px 4px 30px;
            border: 2px solid #aaa;
            border-radius: 5px;
            display: inline-block;
            line-height: 1.4;
            background: url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px, linear-gradient(180deg, white 0%, #e8e8e8 100%);
            text-decoration: none;
            color: #333;
            cursor: pointer;

            &::after {
                content: "他の特集を見る";
            }

            &:hover {
                background: #DFDFDF url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px;
                background: url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px, linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
            }
        }

        &.on {
            span {
                background: url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px, linear-gradient(180deg, white 0%, #e8e8e8 100%);

                &::after {
                    content: "閉じる";
                }

                &:hover {
                    background: #DFDFDF url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px;
                    background: url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px, linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
                }
            }
        }
    }
    & .js-featureAccordion { //開閉アニメーション
        &--target{
            transition: height .7s ease-in-out;
            overflow: hidden;
        }
        &-enter-active{
            animation-duration: .8s;
            animation-fill-mode: forwards;
            animation-name: js-featureAccordion--anime__opend;
        }
        &-leave-active{
            animation-duration: .7s;
            animation-fill-mode: forwards;
            animation-name: js-featureAccordion--anime__closed;
        }
    }
}

@keyframes js-featureAccordion--anime__opend {
    0% {
        display: none;
    }
    100% {
        display:block;
    }
}
@keyframes js-featureAccordion--anime__closed {
    0% {
        display:block;
    }

    100% {
        display:none;
    }
}

#contents .featureArea ul {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
}

#contents .featureArea li img {
    display: block;
    width: 100%;
}

#contents .featureArea li {
    margin: 0 20px 10px 0;
    width: 235px;

    &:nth-child(4n) {
        margin-right: 0;
    }

    &.hide {
        display: none;
    }
}

#contents .featureArea li > a:hover img {
    opacity: 0.8;
}

#contents .featureArea li h3 a {
    font-weight: bold;
}

#contents .featureArea li a {
    font-weight: bold;
}

#contents .featureArea li h4 a {
    padding: 5px 0;
    font-weight: bold;
    line-height: 1.4;
    display: block;
}

#contents .featureArea li p {
    font-size: 88%;
    line-height: 1.4;
}

#contents .featureArea li div {
    min-height: 10em;
}

/*---------------------------------------
.flowArea
----------------------------------------*/
#contents .flowArea {
    margin-bottom: 30px;
    padding: 13px 0 30px;
    background-color: #ECF2FB;
}

.flow {
    margin: 0 auto;
    width: 1000px;

    .topImage {
        padding-left: 100px;
    }

    .note {
        font-size: 81%;
        color: #777;
        text-align: right;
    }
}

#pageTop #contents .flowArea > h2 {
    margin: 0 auto 25px;
    width: 1000px;
    background: url(/assets/pc/images/title_main_02.png) no-repeat left center;
    box-sizing: border-box;
}

#contents .flow > ul {
    margin: 0 -33px 3px 0;
    width: 744px;
    height: auto;
    overflow: hidden;
}

#contents .flow li img {
    display: block;
}

#contents .flow .arrow {
    margin: 0 auto;
    display: block;
    clear: both;
}

#contents .flow .flowBox {
    margin: -10px 0 5px;
    padding: 23px 13px 19px;
    border: 3px solid #2a65b7;
    border-radius: 5px;
    background: #fff;
}

#contents .flow .flowBox h3 {
    margin-bottom: 10px;
    line-height: 1.6;
    text-align: center;
    font-size: 163%;
    color: #2a65b7;
    font-weight: bold;

    & + p {
        margin: 0 auto 15px;
        width: 920px;
        line-height: 1.6;
    }
}

#contents .flow .flowBox ul {
    margin: 0 auto 10px;
    display: flex;
    width: 920px;
}

#contents .flow .flowBox li {
    margin: 0 10px 0 0;
    padding: 15px 15px 15px 0;
    display: flex;
    width: 300px;
    background: #FFF2E5;
    border-radius: 5px;
    color: #333;
    line-height: 1.6;

    &:last-child {
        margin: 0;

        &::after {
            display: none;
        }
    }

    span {
        width: 70px;
        text-align: center;
        font-size: 313%;
        color: #FF8000;
        font-weight: bold;
    }

    p {
        margin: 0;
        width: 215px;

        span {
            vertical-align: top;
            font-size: 50%;
            color: #333;
        }

        em {
            color: #FF4C00;
        }
    }
}

#contents .flow .points h3 {
    color: #ff8200;
}

#contents .flow .flowList {
    li {
        margin-right: 20px;
        padding: 0;
        background-color: inherit;
        display: block;
        position: relative;
        width: 215px;

        .title {
            margin-bottom: 10px;
            padding: 8px 0;
            border-radius: 5px;
            background: #2A65B7;
            color: #fff;
            text-align: center;
        }

        .image {
            margin-bottom: 10px;
            position: relative;
            height: 8.4em;

            img {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50.5%);
            }
        }

        p {
            margin-bottom: 10px;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            right: -16px;
            width: 11px;
            height: 28px;
            background: url(/assets/pc/images/icon_arrow_08.png) no-repeat top right;
        }
    }

    & + img {
        display: block;
        margin: 0 auto;
    }
}

.supportText {
    margin-bottom: 10px;
    line-height: 1.6;
    text-align: center;
    font-size: 163%;
    font-weight: bold;
    color: #2a65b7;

}

.aboutLink {
    margin: 0 auto;
    padding: 13px 0 13px 20px;
    display: block;
    width: 440px;
    background-color: #f5f5f5;
    background-image: url(/assets/pc/images/icon_arrow_01.png), linear-gradient(#fff, #e8e8e8);
    background-repeat: no-repeat;
    background-size: 18px auto, auto;
    background-position: 5% 50%;
    border: 1px solid #aaa;
    border-radius: 5px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    box-sizing: border-box;

    &:hover {
        background-image: url(/assets/pc/images/icon_arrow_01.png), linear-gradient(#e8e8e8, #fff);
        color: #ff4c00;
    }
}

/*---------------------------------------
.requestStepArea
----------------------------------------*/
.requestStepArea {
    margin: 0 auto 50px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    width: 1000px;
    background: #ECF2FB;
    box-sizing: border-box;

    div {
        width: 460px;

        a {
            margin: 0 auto;
            display: block;
            width: 354px;
            height: 106px;
            text-indent: 102%;
            overflow: hidden;
            white-space: nowrap;
            background: url(/assets/pc/images/module_pc_reserch_btn_05.png);

            &:hover {
                background: url(/assets/pc/images/module_pc_reserch_btn_06.png);
            }
        }
    }

    .phoneArea {
        margin: 0 auto;
        padding-top: 8px;
        width: 295px;
        display: block;
        color: #444;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
    }

    b {
        margin: 7px 0;
        display: block;
        color: #ff4c00;
        font-weight: bold;
    }

    .number {
        padding: 0 0 0 42px;
        background: url(/assets/pc/images/icon_free_phone.png) no-repeat left 7%;
        color: #319f2c;
        font-size: 220%;
        font-weight: bold;
        line-height: 1;
        word-wrap: break-word;
        display: block;
        letter-spacing: -0.01em;
        text-align: left;
    }

    .poweredBy {
        display: block;
        color: #777;
        font-size: 60%;
        text-align: right;
    }
}

/*---------------------------------------
.guideArea
----------------------------------------*/
#pageTop #contents section.guideArea {
    margin: 0 auto 36px;
    width: 1000px;

    h2 {
        margin-bottom: 0;
        background: url(/assets/pc/images/title_main_03.png) no-repeat left center;
    }
}

#contents .guideArea h2 + a {
    width: 11em;
}

#contents .guideArea h3 {
    margin-bottom: 20px;
    padding-bottom: 8px;
    border-bottom: 1px dotted #BBB;
    font-size: 125%;
    font-weight: bold;
}

#contents .guideArea ul {
    display: flex;
    flex-wrap: wrap;
}

#contents .guideArea li {
    margin: 0 10px 35px 0;
    width: 326px;
    box-sizing: border-box;

    &:nth-child(3n) {
        margin-right: 0;
    }
}

#contents .guideArea li img {
    display: block;
    width: 92px;
}

#contents .guideArea li > a:hover img {
    filter: alpha(opacity=80);
    opacity: 0.8;
}

#contents .guideArea li div {
    margin: -95px 0 0 98px;
    min-height: 95px;
}

#contents .guideArea li div a {
    font-weight: bold;
    line-height: 1.4;
}

#contents .guideArea .kind li div a {
    margin-bottom: 10px;
}

#contents .guideArea .point li div a {
    margin-bottom: 5px;
}

#contents .guideArea li div p {
    margin-bottom: 0;
    min-height: 5.2em;
    font-size: 88%;
    line-height: 1.4;
    color: #333;
}

/*---------------------------------------
.newsArea
----------------------------------------*/
#pageTop #contents section.newsArea {
    margin: 0 auto 36px;
    width: 1000px;

    h2 {
        margin: 0;
        padding-left: 60px;
        background: url(/assets/pc/images/title_main_04.png) no-repeat left center;
    }

    li {
        margin: 0 20px 40px 0;
        position: relative;
        width: 235px;

        &.pickup {
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                width: 104px;
                height: 24px;
                background: url(/assets/pc/images/icon_pickup_01.png) no-repeat center;
                z-index: 2;
            }
        }

        div {
            padding: 0;
            background: none;
        }

        &:nth-child(4n) {
            margin-right: 0;
        }

        img {
            display: block;
            width: 100%;
        }

        a {
            line-height: 1.6;
            text-decoration: none;
            color: #333;
            display: block;
            position: relative;
            transition: all 0.3s;

            &::before {
                padding: 6px 0;
                border-width: 0 1px 1px 0;
                border-color: #FFF;
                border-style: solid;
                width: 4.5em;
                height: auto;
                color: #FFF;
                font-size: 75%;
                font-weight: bold;
                /* stylelint-disable-next-line */
                font-weight: bold;
                position: absolute;
                left: 0px;
                top: 0px;
                text-align: center;
                line-height: 1;
                display: block;
            }

            &:hover {
                opacity: 0.8;
            }

            span {
                color: #A3A3A3;
                font-size: 88%;
            }
        }

        &.category-kaigo a::before {
            content: "介護";
            background-color: rgba(141, 162, 188, 0.9);
        }

        &.category-medicalcare a::before {
            content: "医療";
            background-color: rgba(152, 136, 181, 0.9);
        }

        &.category-ninchi a::before {
            content: "認知症";
            background-color: rgba(214, 139, 147, 0.9);
        }

        &.category-health a::before {
            content: "健康";
            background-color: rgba(222, 154, 71, 0.9);
        }

        &.category-local a::before {
            content: "施設";
            background-color: rgba(185, 167, 123, 0.9);
        }
    }
}

#contents .newsArea h2 + a {
    width: 12em;
}

.newsArea ul {
    display: flex;
    flex-wrap: wrap;
}

/*---------------------------------------
.faqArea
----------------------------------------*/
#pageTop #contents section.faqArea {
    margin: 0 auto 60px;
    width: 1000px;

    & > h2 {
        margin: 0;
        background: url(/assets/pc/images/title_main_06.png) no-repeat left center;

        & + a {
            margin-top: -52px;
            width: 12em;
        }
    }

    & .questionList {
        & li {
            position: relative;
            border-top: 1px dotted #BBBBBB;
            padding: 13px 0 15px;
            background: #fff;
            &:last-child {
                border-bottom: 1px dotted #BBBBBB;
            }
        }

        &_question {
            display: flex;
        }

        &_questionTitle {
            margin-right: 5px;
            width: 81%;
            display: flex;
            align-items: center;
            position: relative;
            line-height: 1.5;
            padding: 3px 0 0 43px;

            &:before {
                content: "Q";
                position: absolute;
                top: 0;
                left: 0;
                width: 34px;
                height: 34px;
                box-sizing: border-box;
                border-radius: 50%;
                border: 1px solid #BED3F1;
                background: #E0EAF9;
                text-align: center;
                font-size: 113%;
                font-weight: bold;
                color: #2A65B7;
            }
        }

        &_category {
            font-size: 88%;
            color: #777777;
            background: #EEEEEE;
            border-radius: 13px;
            padding: 3px 8px;
            white-space: nowrap;
        }

        &_button {
            position: absolute;
            top: 10px;
            right: 0;
            background: none;
            border: none;
            padding: 0;
            span {
                padding: 4px 10px 4px 30px;
                border: 2px solid #aaa;
                border-radius: 5px;
                display: inline-block;
                line-height: 1.4;
                background: url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px, linear-gradient(180deg, white 0%, #e8e8e8 100%);
                text-decoration: none;
                color: #333;
                cursor: pointer;

                &::after {
                    content: "回答を見る";
                }

                &:hover {
                    background: #DFDFDF url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px;
                    background: url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px, linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
                }
            }

            &.on {
                span {
                    background: url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px, linear-gradient(180deg, white 0%, #e8e8e8 100%);

                    &::after {
                        content: "閉じる";
                    }

                    &:hover {
                        background: #DFDFDF url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px;
                        background: url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px, linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
                    }
                }
            }
        }
        & .answer {
            padding: 0 0 0 43px;
        }

        & .answerTitle {
            margin-bottom: 3px;
            color: #FF8000;
        }

        & .answerText {
            margin-bottom: 0;
        }

        & .js-accordion { //開閉アニメーション
            &--target{
                transition: height .7s ease-in-out;
                overflow: hidden;
                &.state-open {
                    padding-top: 17px;
                }
            }
            &-enter-active{
                animation-duration: .8s;
                animation-fill-mode: forwards;
                animation-name: js-accordion--anime__opend;
            }
            &-leave-active{
                animation-duration: .7s;
                animation-fill-mode: forwards;
                animation-name: js-accordion--anime__closed;
            }
        }
    }
}
  
@keyframes js-accordion--anime__opend {
    0% {
        display: none;
        padding-top: 0;
    }
    100% {
        display:block;
        padding-top: 17px;
    }
}
@keyframes js-accordion--anime__closed {
    0% {
        display:block;
        padding-top: 17px;
    }

    100% {
        display:none;
        padding-top: 0;
    }
}

/*---------------------------------------
#comsArea
----------------------------------------*/
#comsArea {
    margin: -10px auto 50px;
    width: 1000px;

    & > h2 {
        margin: 0;
        background: url(/assets/pc/images/title_main_05.png) no-repeat left center;

        & + a {
            margin-top: -66px;
            width: 10em;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
    }
}

#comsArea li {
    margin: 0 8px 8px 0;
    vertical-align: middle;
    width: 136px;

    &:nth-child(7n) {
        margin-right: 0;
    }

    a {
        display: block;

        &:hover {
            opacity: 0.8;
        }
    }
}

#comsArea li img {
    display: block;
    width: 100%;
}

/*---------------------------------------
.bannerArea
----------------------------------------*/
.bannerArea1 {
    margin: 0 auto 30px;
    width: 1000px;

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 14px;
            width: 324px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(-n+3) {
                margin-bottom: 13px;
            }
        }

        a {
            display: block;

            &:hover {
                opacity: 0.8;
            }
        }

        img {
            display: block;
            width: 100%;
        }
    }
}

.bannerArea2 {
    margin: 0 auto;
    width: 1000px;
    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            margin-right: 14px;
            width: 324px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        a {
            display: block;

            &:hover {
                opacity: 0.8;
            }
        }

        img {
            display: block;
            width: 100%;
        }
    }
}

#pageTop .outsideArea {
    margin: 0 auto 30px;
    padding: 0;
    border-top: none;
    border-bottom: none;
    width: 930px;
    clear: both;
    & a {
        margin-right: 39px;
        & img {
            vertical-align: middle;
        }
    }
}

/*---------------------------------------
.isoArea
----------------------------------------*/
#statusArea .isoArea {
    margin-bottom: 15px;
    padding: 9px;
    border: 1px solid #2A65B7;
    background-color: #DFEAF9;
}

#statusArea .isoArea strong {
    margin-bottom: 7px;
    font-size: 113%;
    font-weight: bold;
    display: block;
}

#statusArea .isoArea strong a {
    padding-left: 5px;
    font-size: 69%;
    vertical-align: middle;
    display: inline-block;
}

#statusArea .isoArea .isoBox {
    padding: 12px 11px 6px;
    background-color: #FFF;
    text-align: center
}

#statusArea .isoArea .isoBox img {
    vertical-align: bottom;
}

#statusArea .isoArea .isoBox > img {
    padding-top: 13px;
    float: left;
    width: 102px;
    height: auto;
}

#statusArea .isoArea .isoBox .nortonArea {
    float: right;
    width: 100px;
}

#statusArea .isoArea .isoBox .nortonArea img {
    width: 100px;
    height: 72px;
}

#statusArea .isoArea .isoBox p {
    margin: 0;
    padding-top: 13px;
    font-size: 88%;
    text-align: left;
    line-height: 1.4;
    clear: both;
}

/*---------------------------------------
.serviceAndMediaArea
----------------------------------------*/
.serviceAndMediaArea {
    margin-bottom: 40px;
    background: #F6F3E9;
    padding: 20px 0;
}

.serviceAndMediaArea a:hover {
    opacity: 0.8;
}

.serviceArea {
    margin-bottom: 38px;
}

.serviceArea .serviceArea-title {
    text-align: center;
    font-weight: bold;
    font-size: 163%;
    margin-bottom: 19px;
}

.serviceArea .serviceArea-list {
    margin: 0 auto;
    display: flex;
    width: 724px;
}

.serviceArea .serviceArea-list li:first-child {
    margin-right: 14px;
}

.serviceArea .serviceArea-list li a {
    display: block;
    line-height: 1;
}

.serviceArea .serviceArea-list li a img {
    width: 355px;
    height: 191px;
}

.mediaArea {
    clear: both;
    margin: 0 auto;
    width: 1000px;
}

.mediaArea .mediaArea-title {
    text-align: center;
    font-weight: bold;
    font-size: 163%;
    margin: 30px auto 15px;
}

.mediaArea .mediaArea-lead {
    text-align: center;
    font-size: 88%;
    margin-bottom: 20px
}

.mediaArea .mediaArea-list {
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    width: 970px
}

.mediaArea .mediaArea-list li {
    float: left;
    width: 180px;
    padding: 3px 7px;
}

.mediaArea .mediaArea-list li img {
    display: block;
    width: 180px;
    height: 100px;
}

#pageTop #freePhoneArea {
    margin: 70px auto;
    width: 1000px;
}

/*---------------------------------------
footer
----------------------------------------*/
body#pageTop footer {
    margin-bottom: 0;
}

/*---------------------------------------
IE対応
----------------------------------------*/
.ieUnSupport {
    padding: 7px 0 8px;
    text-align: center;
    background-color: #ed1c24;
    font-weight: bold;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
}
.ieUnSupport a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

/*---------------------------------------
.voiceArea
----------------------------------------*/
#pageTop #contents section.voiceArea {
    margin: 0 auto 36px;
    width: 1000px;

    h2 {
        margin: 0;
        padding-left: 60px;
        background: url(/assets/pc/images/title_main_07.png) center left/50px no-repeat;
    }

    .voiceList {
        display: flex;
        flex-wrap: wrap;

        .voiceBox {
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            margin: 0 20px 20px 0;
            width: 320px;
            box-sizing: border-box;
            border: 1px solid #aaa;
            border-radius: 5px;

            &_prefName {
                font-size: 90%;
                color: #003BEB;
                display: block;
                &:before {
                    content: '[';
                }
                &:after {
                    content: ']';
                }
            }

            &_facilityArea {
                display: flex;
                margin-bottom: 10px;
            }

            &_facilityImage {
                margin-right: 10px;
                width: 100px;
                line-height: 0;
            }

            &_facilityInfo {
                width: 190px;
                line-height: 1.5;
            }

            &_facilityType {
                font-size: 70%;
                color: #777777;
            }

            &_facilityName {
                font-weight: bold;
            }

            &_totalScore {
                background: #F5F5F5;
                padding: 10px 0;
                margin: 0 0 10px;
                text-align: center;

                &--title {
                    display: inline-block;
                    font-weight: bold;
                    text-align: left;
                }

                &--score {
                    color: #FF4C00;
                    font-weight: bold;
                    margin-left: 5px;
                }

                &--ratingWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 180px;
                    margin: 0 auto;
                }
            }

            &_date {
                color: #777777;
                font-size: 82%;
            }

            &_title {
                display: block;
                line-height: 1.5;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }

            &_detailLink {
                text-align: center;
                color: #003BEB;
                text-decoration: underline;
                &:hover {
                    color: #FF4C00;
                }
            }

            &_listBox {
                min-height: 90px;
            }
            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }
    }

    li {
        img {
            max-width: 100%;
        }

        a {
            display: flex;
            -webkit-box-orient: vertical;
            flex-direction: column;
            flex: 1 0 auto;
            padding: 10px;
            text-decoration: none;
            color: #333333;
            cursor: pointer;

            &:hover {
                background: #FFF2E5;
                border-radius: 5px;
            }
        }
    }
}

#contents .voiceArea h2 + a {
    width: 15em;
}

.voiceArea ul {
    display: flex;
    flex-wrap: wrap;
}

.rating {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    font-size: 23px;
    line-height: 24px;
    width: 75px;
    margin: 0 auto;
    position: relative;
    padding: 0 0 5px 0;

    &-upper {
        color: #FDD835;
        padding: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    &-lower {
        padding: 0;
        display: flex;
        z-index: 0;
    }
}
